// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/greatvibes/v18/RWmMoKWR9v4ksMfaWd_JN-XC.ttf) format('truetype');
}
@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/greatvibes/v18/RWmMoKWR9v4ksMfaWd_JN-XC.ttf) format('truetype');
}
@font-face {
  font-family: 'Monsieur La Doulaise';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/monsieurladoulaise/v18/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5e.ttf) format('truetype');
}
@font-face {
  font-family: 'Pinyon Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pinyonscript/v21/6xKpdSJbL9-e9LuoeQiDRQR8aOI.ttf) format('truetype');
}
.mainContainer {
  width: 100vw;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}
`, "",{"version":3,"sources":["https:/fonts.googleapis.com/css2?family=Great+Vibes&display=swap","webpack://./src/pages/Main.less","https:/fonts.googleapis.com/css2?family=Great+Vibes&family=Monsieur+La+Doulaise&family=Pinyon+Script&display=swap"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oGAAA;ACCF;ACNA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oGAAA;ADQF;ACNA;EACE,mCAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,wHAAA;ADQF;ACNA;EACE,4BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yGAAA;ADQF;AAxBA;EACE,YAAA;EACA,6BAAA;EACA,6CAAA;AA0BF","sourcesContent":["@font-face {\n  font-family: 'Great Vibes';\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url(https://fonts.gstatic.com/s/greatvibes/v18/RWmMoKWR9v4ksMfaWd_JN-XC.ttf) format('truetype');\n}\n","@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Monsieur+La+Doulaise&family=Pinyon+Script&display=swap');\n\n.mainContainer {\n  width: 100vw;\n  background-color: transparent;\n  transition: background-color .3s ease-in-out;\n}","@font-face {\n  font-family: 'Great Vibes';\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url(https://fonts.gstatic.com/s/greatvibes/v18/RWmMoKWR9v4ksMfaWd_JN-XC.ttf) format('truetype');\n}\n@font-face {\n  font-family: 'Monsieur La Doulaise';\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url(https://fonts.gstatic.com/s/monsieurladoulaise/v18/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5e.ttf) format('truetype');\n}\n@font-face {\n  font-family: 'Pinyon Script';\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url(https://fonts.gstatic.com/s/pinyonscript/v21/6xKpdSJbL9-e9LuoeQiDRQR8aOI.ttf) format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
